
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { formatFinancialAmounts, formatTwoDecimal} from '../../utils/format'

export default Vue.extend({
  name: "datasourceinformation",
  data: () => ({
    data: [],
    isLoading: false,
  }),
  computed: {
    yearSelected() {
      return this.$store.getters["ddcreport/getYearSelected"].year;
    },
    title() {
      return i18n.t("reports.ddcreports.listReports.datasourceinformation");
    },
    headers() {
      return [
        {
          text: i18n.t("reports.ddcreports.yearselected"),
          value: "surveyYear",
          width: "15%",
        },
        {
          text: i18n.t("reports.ddcreports.total_NMOs"),
          value: "totalNMOs",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.data_Source"),
          value: "dataSource",
        },
        {
          text: i18n.t("reports.ddcreports.frequency"),
          value: "frequency",
          align: "end",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.ddcreports.percentage"),
          value: "percentage",
          align: "end",
          formatter: (val: number): number | string => formatTwoDecimal(val)
        },
      ];
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      axios
        .get(`Report/DDC/DataSource-Breakdown`, {
          params: { year: this.yearSelected },
        })
        .then((res) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(`Report/DDC/DataSource-Breakdown/export`, {
          params: { year: this.yearSelected },
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.ddcreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.yearSelected) {
      this.getData();
    }
  },
  watch: {
    yearSelected() {
      this.getData();
    },
  },
});
